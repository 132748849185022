<template>
  <div class="speakCourseBg">
    <div class="speakCoursewidth">
      <div>
        <img src="images/leftCt.png" class="openCt" @click="table = true">
      </div>
      <div class="speakCourseBlack">
        <div class="fullScreen">

          <!--          <el-button v-if="!courseDrawer" type="primary" plain @click="openDraw">显示</el-button>-->
        </div>
        <div v-if="exercisesShow">
          <div>
            <exercises-s-c
                ref="std"
                v-if="resourceExercises.exercisesType === 'SC'"
                :exercisesInfo="resourceExercises"
                :openType="2"
                :isShowRightAndAnalysis="true"
            ></exercises-s-c>
            <exercises-m-c
                ref="std"
                v-if="resourceExercises.exercisesType === 'MC'"
                :exercisesInfo="resourceExercises"
                :openType="2"
                :isShowRightAndAnalysis="true"
            ></exercises-m-c>
            <!-- 填空题 -->
            <exercises-f-b
                ref="std"
                v-if="resourceExercises.exercisesType === 'FB'"
                :exercisesInfo="resourceExercises"
                :openType="2"
                :isShowRightAndAnalysis="true"
            >
            </exercises-f-b>
            <!-- 判断题 -->
            <exercises-t-f
                ref="std"
                v-if="resourceExercises.exercisesType=== 'TF'"
                :exercises-info="resourceExercises"
                :open-type="2"
                :isShowRightAndAnalysis="true"
            ></exercises-t-f>
            <!-- 简答题 -->
            <exercises-s-a-q
                ref="std"
                v-else-if="resourceExercises.exercisesType === 'SAQ'"
                :exercisesInfo="resourceExercises"
                :openType="2"
                :isShowRightAndAnalysis="true"
            >
            </exercises-s-a-q>
          </div>
        </div>
        <!-- 教学设计 -->
        <div v-if="designWhindow">
          <div class="resourceImgas" v-for="(item,index) in resourceImg" :key="index">
            <img :src="item?item:''" alt=""/>
          </div>
        </div>
        <!--    ppt-->
        <div class="pptShowWhindow" v-if="pptWhindow">
          <div>
            <div class="coursePPtImgFlex">
              <div>
                <div class="pptresourceImgas" v-for="(item,index) in pptResourceImg" :key="index">
                  <img :src="item?item:''" alt="" @click="changePPtImg(item)" crossorigin="anonymous"/>
                </div>
              </div>
              <div :style="style" class="palettePosition" id="con_lf_top_div">
                <!--                <el-image :src="coursePPtimg"   class="coursePPtBigIMgs"></el-image>-->
                <sketchpad ref="sketchpad" :coverImgUrl="coursePPtimg" :pptResourceImg="pptResourceImg"/>
              </div>
            </div>
          </div>
        </div>
        <!--    视频播放-->
        <div v-if="videoWhindow">
          <el-dialog
              :visible.sync="videoWhindow"
              :show-close="true"
              :append-to-body="true"
              :destroy-on-close="true">
            <div class="resourceImgas" v-if="videoWhindow">
              <vue-aliplayer-v2 ref="VueAliplayerV2" :options="videoOptions"/>
            </div>
          </el-dialog>
        </div>
        <div class="resourceImgas" v-if="mydesignWhindow">
          <img :src="myresourceImg" alt=""/>
        </div>
      </div>
      <div>
        <img src="images/courseRight.png" class="openright" @click="concealBtn">
      </div>
      <div class="resourceListBg" v-if="courseDrawer">
        <div class="drawingBtn">

          <!--          <el-button type="primary" @click="concealBtn">隐藏</el-button>-->
          <el-button type="primary" plain @click="changeDrawing">全屏</el-button>
          <el-button type="primary" @click="publishCourse">记录进度</el-button>
          <el-button type="primary" plain @click="goPrepareCourseCenter()">返回</el-button>
        </div>
        <el-collapse v-model="activeName" accordion @change="chooseChapter(chaperData)">
          <el-collapse-item v-for="(item1,index1) in resourceTabArr" :key="index1" :name=index1>
            <div slot="title" class="resourceListName">
              {{ item1.name }}
            </div>
            <div v-for="(item,index) in coursemiCrolecture" :key="index" class="resourcecourseTitleFlex"
                 @click="selectResource(item)">
              <span>{{ index + 1 }}.</span>
              <p class="resourcecourseTitle">{{ item.resourceName ? item.resourceName : item.exercisesTitle }}</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <el-drawer
        title=""
        :visible.sync="table"
        direction="ltr"
        size="22%">
      <div>
        <div>
          <el-menu
              text-color="#333333"
              active-text-color="#49B151"
              :default-active="activeId"
              :default-openeds="[activeId]"
              class="el-menu-vertical-demo"

          >
            <el-submenu v-for="(item, index) in courseDetailArr"
                        :key="item.id" :index="item.id">
              <template slot="title">
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item-group
                  v-for="(itemChildren, indexChildren) in item.children"
                  :key="indexChildren"
              >
                <el-menu-item :index="itemChildren.id">
                  <div slot="title" @click="chooseChapter(itemChildren)">
                    <span class="jieTitle">{{ itemChildren.name }}</span>
                    <!-- <span class="trySee">试看</span> -->
                    <!--                     <img v-if="itemChildren.freeData==0" src="images/lock.png" alt="" class="lock"/>-->
                  </div>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  audioImg, deleteTeacherExercises,
  operationTopic,
  playVoucher,
  selectAddTeacherResourceList,
  selectLoginTeacherResourceList,
  teacherUpdateProgress
} from "@/api";
import VueAliplayerV2 from 'vue-aliplayer-v2';
import {filePlayType} from "@/utils/fileUtil.js";
import ExercisesTF from "@/components/exercisesList/exercisesTF";
import ExercisesSC from "@/components/exercisesList/exercisesSC";
import ExercisesMC from "@/components/exercisesList/exercisesMC";
import ExercisesFB from "@/components/exercisesList/exercisesFB";
import ExercisesSAQ from "@/components/exercisesList/exercisesSAQ";
import sketchpad from '@/components/Sketchpad'
import screenfull from "screenfull/dist/screenfull.js";

export default {
  components: {ExercisesTF, ExercisesSC, ExercisesMC, ExercisesFB, ExercisesSAQ, VueAliplayerV2, sketchpad},
  data() {
    return {
      style: {},
      courseResourceDetail: {},
      activeName: '',
      coursemiCrolecture: [],
      resourceTabArr: [
        {id: 1, name: '教学目标'},
        {id: 2, name: '教学设计'},
        {id: 3, name: '电子教材'},
        {id: 8, name: '习题'},
        {id: 5, name: '微课'},
        {id: 6, name: '动画'},
        {id: 7, name: '教学视频'},
        {id: 4, name: '拓展资料'},
      ],
      exercisesShow: false,
      videoWhindow: false,
      pptWhindow: false,
      designWhindow: false,
      resourceExercises: {},
      resourceImg: [],
      pptResourceImg: [],
      videoOptions: {},
      coursePPtimg: '',
      table: false,
      courseDetail: {},
      courseDetailArr: [],
      chaperData: {},
      myresourceImg: '',
      mydesignWhindow: false,
      activeId: '',
      courseChapter: {
        id: '',
        name: ''
      },
      fullscreen: false,
      courseDrawer: true
    }
  },
  methods: {
    quit(e) {
      let key = e.keyCode;
      if (key === 27) {
      }
    },
    //全屏显示
    async changeDrawing() {
      /*
      * 1.设置当前dom全屏  this.style = { position: 'fixed', top ...... }
      * 2.设置当前页面全屏  await screenfull.toggle();
      * 3. mounted中监听 【Esc按键】 按下事件【keyup】
      * 4. 按下Esc时将 【1】 中设置的style重置为空this.style = {}
      *
      * */

      this.style = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: '100%',
        right: 0,
        "z-index": 999999
      }
      await screenfull.toggle();
    },
    //  获取登陆老师章节信息
    getLoginTeacherResourceList() {
      let data = {
        courseId: this.courseDetail.id,
        classId: this.$route.query.classId
      };
      selectLoginTeacherResourceList(data).then(res => {
        res.data.forEach((item) => {
          item.isLastLecture && (this.activeId = String(item.id))
        })
        let courseItemList = res.data
        const listToTree = (listArr, pid) => {
          let tree = [];
          listArr.forEach((item, index) => {
            if (item.pid === pid) {
              let child = listToTree(courseItemList, item.id)
              let data = {...item}
              child && child.length && (data['children'] = child)
              tree.push(data)
            }
          })
          return tree;
        }
        this.courseDetailArr = listToTree(courseItemList, '0')
      })
    },
    //选择章节
    chooseChapter(item) {
      this.courseChapter = item
      this.table = false;
      this.exercisesShow = false;
      this.videoWhindow = false;
      this.designWhindow = false;
      this.pptWhindow = false;
      this.chaperData = item;
      let data = {
        platformCourseId: item ? item.platformCourseId : this.courseResourceDetail.platformCourseId,
        platformCourseItemId: item ? item.id : this.courseResourceDetail.id,
        page: 1,
        size: 50,
        sourceDiff: 'Objectives'
      }
      let map = {
        0: 'Objectives',
        1: 'TeacherDoc',
        2: 'Ebook',
        3: 'courseExercises',
        4: 'MicroLecture',
        5: 'Animation',
        6: 'Teaching',
        7: 'Other',
      }
      if (this.activeName) {
        data['sourceDiff'] = map[this.activeName]
      }
      selectAddTeacherResourceList(data).then(res => {
        this.coursemiCrolecture = res.data.records;
        this.coursemiCrolecture.forEach((item, index) => {
          if (item.customText) {
            let customTextNew = JSON.parse(item.customText)
            this.$set(this.coursemiCrolecture[index], "exercisesTitle", customTextNew.exercisesTitle);
            item.customText = JSON.parse(item.customText)
          }
        })
      });
    },
    getExercises() {
      let query = {
        platformCourseId: this.chaperData ? this.chaperData.platformCourseId : this.courseResourceDetail.platformCourseId,
        platformCourseItemId: this.chaperData ? this.chaperData.id : this.courseResourceDetail.id,
        page: 1,
        size: 100
      }
      operationTopic(query).then(res => {
        res.data.records.forEach((item, index) => {
          if (item.exercisesBody) {
            item.questionOption = item.exercisesBody.split('\@')
          }
          if (item.correctAnswer) {
            item.correctAnswerArr = item.correctAnswer.split(',')
          }
          if (item.exercisesType == 'FB') {
            item.exercisesBody = JSON.parse(item.exercisesBody)
          }
        })
        this.coursemiCrolecture = res.data.records;
      })
    },
    //  查看资源
    selectResource(item) {
      if (item.customText) {
        this.resourceExercises = item.customText;
        this.exercisesShow = true
        this.mydesignWhindow = false;
        this.pptWhindow = false;
      } else if (item.exercisesType) {
        this.resourceExercises = item
        this.exercisesShow = true;
        this.mydesignWhindow = false;
        this.pptWhindow = false;
      } else if (item.resourceType === 2) {
        let fileType = item.resourceName ? filePlayType(item.resourceName) : filePlayType(item.fileOriginalName);
        if (fileType === 'avTypeAry' || fileType === 'audioTypeAry') {
          let dataArr = []
          let data = {
            thirdPartyStorageId: item.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList: dataArr}).then((res) => {
            res.data.forEach(item => {
              this.videoOptions.playauth = item.playAuth
            })
            this.videoWhindow = true;
            this.mydesignWhindow = false;
            this.pptWhindow = false;
          })
        }
        if (fileType === 'imageTypeAry' || fileType === 'pdfTypeAry') {
          let dataArr = []
          let data = {
            thirdPartyStorageId: item.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList: dataArr}).then(res => {
            res.data.forEach(item => {
              this.myresourceImg = item.url;
            })
            this.mydesignWhindow = true;
            this.pptWhindow = false;
            this.videoWhindow = false;
          });

        }
        if (fileType === 'officeTypeAry') {
          let dataArr = []
          let data = {
            thirdPartyStorageId: item.thirdPartyStorageId
          }
          dataArr.push(data)
          audioImg({queryDataList: dataArr}).then(res => {
            res.data.forEach(item => {
              this.pptResourceImg = item.aliVideoOssUrl.split(',');
              this.coursePPtimg = this.pptResourceImg[0]
            })
          });
          this.pptWhindow = true;
          this.mydesignWhindow = false;
          this.videoWhindow = false;
        }
      } else {
        let fileType = filePlayType(item.resourceName);
        if (fileType === 'avTypeAry' || fileType === 'audioTypeAry') {
          this.videoOptions.vid = item.thirdPartyStorageId
          let data = {
            id: item.resourceId
          }
          playVoucher(data).then((res) => {
            this.videoOptions.playauth = res.data.playAuth
            this.exercisesShow = false;
            this.videoWhindow = true;
            this.designWhindow = false;
            this.pptWhindow = false;
          })
        }
        if (fileType === 'imageTypeAry' || fileType === 'pdfTypeAry') {
          this.exercisesShow = false;
          this.videoWhindow = false;
          this.designWhindow = true;
          this.pptWhindow = false;
          let data = {
            id: item.resourceId
          }
          playVoucher(data).then(res => {
            this.resourceImg = res.data.playAuth.split(',')
          })
        }
        if (fileType === 'officeTypeAry') {
          this.exercisesShow = false;
          this.videoWhindow = false;
          this.designWhindow = false;
          this.pptWhindow = true;
          let data = {
            id: item.resourceId
          }
          playVoucher(data).then(res => {
            this.pptResourceImg = res.data.playAuth.split(',');
            this.coursePPtimg = this.pptResourceImg[0]

          })
        }
      }
    },
    //  点击小图切换大图
    changePPtImg(item) {
      this.coursePPtimg = item
    },
    //  完成授课
    publishCourse() {
      let data = {
        classId: this.$route.query.classId,
        nowPlatformCourseItemId: this.chaperData ? this.chaperData.id : this.courseResourceDetail.id,
        nowPlatformCourseItemName: this.chaperData ? this.chaperData.name : this.courseResourceDetail.name,
        dataType: ''
      };
      if (this.courseDetail.ownerType == 'platform') {
        data['dataType'] = 'platform'
        data['id'] = this.$route.query.progressId
      } else {
        data['dataType'] = 'teacher'
        data['labelName'] = this.$route.query.className;
        data['platformCourseId'] = this.courseDetail.id
        if (this.$route.query.progressId) {
          data['id'] = this.$route.query.progressId
        }
      }
      teacherUpdateProgress(data).then(res => {
        if (res.code === 0) {
          this.$message({
            message: '授课完成',
            type: 'success'
          });
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    goPrepareCourseCenter() {
      this.$confirm('如果已记录,请点击确定', '请记录本节课进度', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.$route.query.coursePT) {
          this.$router.push({path: '/classCenter'});
          sessionStorage.setItem('coursePT', "2")
        } else if (this.$route.query.courseMySelf) {
          this.$router.push({path: '/classCenter'});
          sessionStorage.setItem('courseMySelf', "8-1")
        }
        // this.$router.push({path:'prepareCourseCenter',query:{progressId:this.$route.query.progressId,className:this.$route.query.className,classId:this.$route.query.classId,courseId:this.courseDetail.id}})
      }).catch(() => {

      });
    },
    //  隐藏
    concealBtn() {
      this.courseDrawer = !this.courseDrawer;
    },
    //  显示
    openDraw() {
      this.courseDrawer = true;
    },
    closeScreenFull() {

    },
    // watchFullScreen() {
    //   let self = this;
    //   window.addEventListener('keydown', function (e) {
    //     if (e.keyCode == 27) {
    //       self.style = {}
    //     }
    //   })
    // }
  },
  mounted() {
    let courseDetail = sessionStorage.getItem('courseDetail')
    this.courseDetail = JSON.parse(courseDetail)
    this.courseResourceDetail = JSON.parse(sessionStorage.getItem('courseResourceDetail'));
    this.chooseChapter();
    this.getLoginTeacherResourceList();
    // 移除【监听全屏改变、监听鼠标按下】监听事件
    window.removeEventListener('keydown', addKeyDownEvent)
    window.removeEventListener('fullscreenchange', addFullScreenChangeEvent)
    // 监听全屏改变
    window.addEventListener('fullscreenchange', addFullScreenChangeEvent)
    // // 监听键盘按下
    window.addEventListener('keydown', addKeyDownEvent)
    let me = this;
    function addKeyDownEvent(e){
      if (e.keyCode === 27) {
        e.preventDefault()
        me.style={}
      }
      return true
    }

    function addFullScreenChangeEvent(e){
      if(screenfull.isFullscreen)return;
      me.style={}
    }
  },
}
</script>
<style scoped>
@import "../../../public/css/speakCourse.css";
@import "../../../public/css/courseDetail.css";
</style>
