import { render, staticRenderFns } from "./Sketchpad.vue?vue&type=template&id=288cc165&scoped=true"
import script from "./Sketchpad.vue?vue&type=script&lang=js"
export * from "./Sketchpad.vue?vue&type=script&lang=js"
import style0 from "./Sketchpad.vue?vue&type=style&index=0&id=288cc165&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "288cc165",
  null
  
)

export default component.exports